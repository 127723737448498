import * as React from 'react'
import { ITransitionButton } from '../../../../common/shared/smSchema15'
import * as Styles from '../styles'
import nextButtonImage from '../../../../images/animation_200_kvr0swhq.gif'

interface ComponentProps {
	action: ITransitionButton
	doAction: (action: ITransitionButton) => void
	disabled: boolean

}

const ActionButton:React.FC<ComponentProps> = (props) => {
	const { action, doAction, disabled } = props
	const { label, event } = action

	if (event === 'next') {
		return (
			<Styles.NextButton onClick={() => doAction(action)} disabled={disabled}>
				<Styles.NextArrow src={nextButtonImage} alt="animated gif" />
			</Styles.NextButton>

		// <Styles.ActionButton onClick={() => doAction(action)} disabled={disabled}>
		// Next
		// </Styles.ActionButton>
		)
	}

	if (event && label) {
		return (
			<Styles.ActionButton onClick={() => doAction(action)} disabled={disabled}>
				{label}
			</Styles.ActionButton>
		)
	}
	return <></>
}

export default ActionButton
