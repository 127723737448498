import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState } from 'react'
import uniqId from 'uniqid'
import { IStateDefinition, IStateTransitionDefinition } from '../common/shared/smSchema15'

interface TickState {
	state: IStateDefinition | IStateTransitionDefinition | undefined
	stateId: string
}

export interface HookOutput {
	stickieState: TickState
	setCutOffDate: any
}

const toDate = (firebaseTimestamp:FirebaseFirestore.Timestamp):Date => {
	const { seconds, nanoseconds } = firebaseTimestamp
	return new firebase.firestore.Timestamp(seconds, nanoseconds).toDate()
}

const useStickieState = (stickieId: string, uid:string | undefined): HookOutput => {
	const [stickieState, setStickieState] = useState<TickState>({ state: undefined, stateId: uniqId() })
	const [cutOffDate, setCutOffDate] = useState<Date | undefined>()

	useEffect(() => {
		if (uid) {
			const ref = firebase.firestore()
				.collection('stickerStats')
				.doc(stickieId)
				.collection('users')
				.doc(uid)
				.collection('state')
				.doc('state')

			const unsubscribe = ref.onSnapshot((doc) => {
				const state = doc.data() as IStateDefinition | IStateTransitionDefinition | undefined
				// console.log(`New State=${JSON.stringify(state)}`)

				if (cutOffDate && state) {
					const { updatedOn } = state as any

					// console.log(`updatedOn=${toDate(updatedOn)}`)
					// console.log(`cutOffDate=${cutOffDate}`)
					const t = toDate(updatedOn) >= cutOffDate
					// console.log(`updatedOn >= cutOffDate=${t}`)

					if (t) {
						setStickieState({
							state,
							stateId: uniqId(),
						})
					}
				} else {
					setStickieState({
						state,
						stateId: uniqId(),
					})
				}
			})

			return (() => {
				unsubscribe()
			})
		}
		return () => {}
	}, [stickieId, uid, cutOffDate])

	return {
		stickieState,
		setCutOffDate,
	}
}

export default useStickieState
