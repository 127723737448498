import * as React from 'react'
import { isEmpty } from 'lodash'
import { IMessage } from '../../../../hooks/useStickieMessages'
import * as Styles from '../styles'
import Tail from '../../../../images/svg/chatbubbletail.svg'

interface Props {
  content: IMessage['content']
	us: boolean
	imageUrl: string
}

const MessageBubble:React.FC<Props> = (props) => {
	const {
		content, us, imageUrl,
	} = props

	return (
		<Styles.MessageBubble us={us}>
			{
				!isEmpty(imageUrl) && (
					<Styles.MessageImageContainer>
						<Styles.MessageImage src={imageUrl} alt="message" />
					</Styles.MessageImageContainer>
				)
			}
			<Styles.ChatBubbleTail><Tail width={28.85} height={20} /></Styles.ChatBubbleTail>
			{content}
		</Styles.MessageBubble>
	)
}

export default MessageBubble
