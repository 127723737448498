import styled, { css } from 'styled-components'

export const EmailInput = styled.input<{disabled:boolean}>`
	overflow: hidden;
	width: 200px;
	height: 48px;
	max-height: 75px;
	margin: 0px;
	padding: 5px 10px 5px 18px;
	text-align: left;
	
	font-family: 'DM Sans';
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: var(--gray1);

	border-top-left-radius: 24px;
	border-bottom-left-radius: 24px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	
	&:focus {
		outline: none;
		font-weight: 400;
		font-size: 16px;
	}
	
	background: var(--white1);
	border: 1px solid var(--lightGray);
	border-right: 0px;
	box-sizing: border-box;

	${(props) => props.disabled && css`
     opacity 0.7;
  `}
`

export const SubmitButton = styled.input<{disabled:boolean}>`
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	height: 48px;
	width:120px;
	color: var(--white1);
	background-color: var(--brandColor);
  font-weight: 500;
  /* letter-spacing: -0.26px; */
  line-height: 19px;
  
	${(props) => props.disabled && css`
     opacity 0.8;
  `}
	
	border-top-right-radius: 24px;
	border-bottom-right-radius: 24px;

`

export const EmailContainer = styled.div`
	/* width: auto; */
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
`
