
import React from 'react'
import { SlideshowTypeKeyed } from '../../common/shared/schema12'
import SlideShow from '../../components/slideshows/Schema15'
import Wrapper from '../../components/slideshows/Wrapper'

const PublishedSlideshowLoader = (result:any):any => {
	const { pageContext }: {pageContext:any} = result
	const { slideshow }:{slideshow:SlideshowTypeKeyed} = pageContext
	return (
		<>
			<Wrapper slideshow={slideshow}>
				<SlideShow />
			</Wrapper>
		</>
	)
}

export default PublishedSlideshowLoader
