import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import { isEmpty } from 'lodash'
import sha1 from 'sha1'
import { IMessage } from '../../../../common/shared/smSchema15'
import MessageBubble from '../MessageBubble'
import * as Styles from '../styles'

interface Props {
  message: IMessage | string | undefined
}

const MessageList:React.FC<Props> = (props) => {
	const { message } = props

	let text = ''
	let imageUrl = ''
	if (message) {
		if (typeof message === 'string') {
			text = message
		} else {
			text = (message as IMessage).text
			imageUrl = (message as IMessage).imageUrl
		}
	}

	// const keyId = uniqid()
	const keyId = `${sha1(text)}`

	if (isEmpty(text)) {
		return <></>
	}
	return (
		<AnimatePresence initial={false}>
			<Styles.MessagesBubbleContainer
				key={keyId}
				// positionTransition
				initial={{ opacity: 0, y: 100 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, transition: { duration: 0.15 } }}
				us={false}
			>
				<MessageBubble
					imageUrl={imageUrl}
					key={keyId}
					content={text}
					us={false}
				/>
			</Styles.MessagesBubbleContainer>
		</AnimatePresence>
	)
}
export default MessageList
