import React, { useContext, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { isEqual } from 'lodash'
import * as Styles from '../styles'
import EmailComponent, { IEmail } from '../../../Email2'
import { MixpanelContext, AppStateEvent } from '../../../../common/tracking'
import {
	SlideshowContext, SlideshowContextProps,
} from '../../SlideshowProvider'
import loadingGif from '../../../../images/spinner.gif'
import usePrevious from '../../../../hooks/usePrevious'
import { SlideshowTypeKeyed } from '../../../../common/shared/schema12'

type FormStages = 'idle' | 'inprocess' | 'success'

interface ComponentProps {
	onDismiss: Function
	onSuccess: Function
}

const CollectEmail = React.memo<ComponentProps>((props) => {
	const { onSuccess } = props
	const [stage, setStage] = React.useState<FormStages>('idle')
	const mixpanel = useContext(MixpanelContext)

	const previousStage = usePrevious(stage)

	const { slideshow }:Partial<SlideshowContextProps> = useContext(SlideshowContext)
	const { id } = slideshow as SlideshowTypeKeyed

	useEffect(() => {
		mixpanel.track(AppStateEvent.userPresentedWithSubscriptionEmailForm)
	}, [mixpanel])

	useEffect(() => {
		if (!isEqual(previousStage, stage) && stage) {
			if (stage === 'success') {
				// mixpanel.track(AppStateEvent.userSubscribedToNPC)
				onSuccess()
			}
		}
	}, [mixpanel, previousStage, stage, onSuccess])

	/*
	const handleCancel = ():void => {
		mixpanel.track(AppStateEvent.userSaidNoToSubscribingToNPC)
		onDismiss()
	}
	*/

	const handleSubmit = (email:string):void => {
		setStage('inprocess')
		const sendData = {
			stickieId: id,
			email,
		}
		// console.log(`fnSubscribeToStickie=${JSON.stringify(sendData)}`)
		const fnDoAction = firebase.functions().httpsCallable('fnSubscribeToStickie')
		fnDoAction(sendData)
			.then(() => {
				mixpanel.track(AppStateEvent.userSubscribedToNPC, {
					email,
				})
				setStage('success')
			})
	/*
			setTimeout(() => {
			setStage('success')
		}, 5000)
		*/
	}

	return (
		<Styles.SignUpForm>

			{
				stage === 'idle' && (
					<>
						<EmailComponent
							defaultValue="your email"
							onSubmit={(data:IEmail) => {
								handleSubmit(data.email)
							}}
							disabled={false}
							buttonText="Get Access"
						/>
					</>
				)
			}
			{
				(stage === 'inprocess') && (
					<>

						<Styles.SpinnerImage src={loadingGif} alt="animated gif" />

					</>
				)
			}

			{/* <Styles.RightSide>
				 {
					stage === 'idle' && (
						<>
							<Styles.CancelButton onClick={() => handleCancel()}>No thanks</Styles.CancelButton>
						</>
					)
				}
				{
					(stage === 'inprocess' || stage === 'success') && (
						<>

							<Styles.SpinnerImage src={loadingGif} alt="animated gif" />

						</>
					)
				}
			</Styles.RightSide> */}

		</Styles.SignUpForm>
	)
})

export default CollectEmail
