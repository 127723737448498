import React, { useEffect } from 'react'
import * as Styles from './styles'
import usePrevious from '../../hooks/usePrevious'

interface ComponentProps{
	// onChange: Function
	onSubmit: Function
	defaultValue: string
	buttonText: string
	disabled: boolean
}

export interface IEmail {
	email: string
	isValid: boolean
}

function validateEmail(email:string|undefined|null):boolean {
	if (!email) return false
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

const EmailComponent = React.memo<ComponentProps>((props) => {
	const {
		onSubmit, defaultValue, buttonText = 'Sign in', disabled,
	} = props

	const [email, setEmail] = React.useState<IEmail>(
		{
			email: defaultValue,
			isValid: validateEmail(defaultValue),
		},
	)

	const previousEmail:IEmail = usePrevious(email)

	const handleEmailChange = (event:any):void => {
		const newVal = event.target.value
		setEmail({ email: newVal, isValid: validateEmail(newVal) })
	}

	const handleSubmit = (event:any):void => {
		onSubmit(email)
		event.preventDefault()
	}

	useEffect(() => {
		if (previousEmail && email && (previousEmail.email !== email.email)) {
			setEmail({ ...email, isValid: validateEmail(email.email) })
		}
	}, [previousEmail, email])

	return (

		<form onSubmit={handleSubmit}>
			<Styles.EmailContainer>

				<Styles.EmailInput
					onChange={handleEmailChange}
					placeholder="Email"
					maxLength={80}
					id="email"
					name="email"
					autoComplete="on"
					// disabled={disabled}
					disabled={false}
				/>
				<Styles.SubmitButton
					value={buttonText}
					type="submit"
					disabled={!email.isValid || disabled}
				/>

			</Styles.EmailContainer>
		</form>

	)
})
export default EmailComponent
